/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import styled from "styled-components"

const CustomLink = styled.a`


`

const styles = (variant) => (
  {
    display: 'inline-block',
    fontSize: 'inherit',
    fontWeight: 'normal',

    variant: `buttons.${variant}`,

  }

)

const Button = ({
  className,
  text,
  to,
  variant = 'primary',
  children,
  ...props
}) => {

  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <Link 
        {...props} 
        className={className} 
        to={to}
        sx={ styles(variant)} 
      >  
        
        {text}

      </Link>
    )
  }

  return(
    <CustomLink 
      {...props} 
      className={className}
      sx={styles(variant)} 
      href={to}
      target={typeof to !== 'undefined' ? "_blank" : false}
      rel={typeof to !== 'undefined' ? "noopener noreferrer" : false}
    >
    
      {text}

    </CustomLink>
  )

}


export default Button