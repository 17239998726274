/** @jsx jsx */
import React from 'react'
import { Box, jsx } from 'theme-ui'
import styled from '@emotion/styled'

import FlameIcon from '../images/icons/Flame.svg'

const StyledIcon = styled(FlameIcon)`
  position: absolute;
  bottom: -45px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width:768px) {
    height: 30px;
    width: auto;
    bottom: -20px;
  }
`

const HeroBorder = ( {sectionColor, props} ) => {
  
  return (
    <>
    { sectionColor === 'white' && 
      <Box 
        {...props}
        width={'100%'} sx={{

        borderColor: 'primary',
        borderWidth: ['2px','3px'],
        borderStyle: 'solid',
        position: 'relative',
        zIndex: 10,

        '&:before': {
          content: '""',
          width: ['50px','100px'],
          height: ['25px','50px'],
          backgroundColor: 'white',
          borderTopLeftRadius: ['52px','105px'],
          borderTopRightRadius: ['52px','105px'],
          borderWidth: ['3px', '5px'],
          borderColor: 'primary',
          borderStyle: 'solid',
          borderBottom: '0',
          position: 'absolute',
          top: ['-26px','-52px'],
          left: '50%',
          transform: 'translateX(-50%)',
        }
      }}>
        <StyledIcon />
      </Box>
    }
    { sectionColor === 'darkGray' && 
      <Box 
        {...props}
        width={'100%'} sx={{

        borderColor: 'primary',
        borderWidth: ['2px','3px'],
        borderStyle: 'solid',
        position: 'relative',
        zIndex: 10,

        '&:before': {
          content: '""',
          width: ['50px','100px'],
          height: ['25px','50px'],
          backgroundColor: 'darkGray',
          borderTopLeftRadius: ['52px','105px'],
          borderTopRightRadius: ['52px','105px'],
          borderWidth: ['3px', '5px'],
          borderColor: 'primary',
          borderStyle: 'solid',
          borderBottom: '0',
          position: 'absolute',
          top: ['-26px','-52px'],
          left: '50%',
          transform: 'translateX(-50%)',
        }
      }}>
        <StyledIcon />
      </Box>
    } 
    </>
  )
}

export default HeroBorder