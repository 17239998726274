/** @jsx jsx */

import React from 'react';
import styled from '@emotion/styled';
import { Flex, jsx } from 'theme-ui';
import Button from './button';
import PropTypes from 'prop-types';


const Form = styled.form`
  width: 100%;
  font-size: 18px;

  input[type="text"], input[type="email"], input[type="tel"], textarea {
    border: 2px solid black;
    padding: 18px;
    width: 100%;
    margin-bottom: 20px;
    background: none;
  }

  #firstname {
    margin-right: 20px;
  }
  #name {
    margin-right: 20px;
  }
`

class ContactForm extends React.Component {

  render () {

    const { name, minimal } = this.props;

    return (

      <Form name={name} method="POST" data-netlify="true" action="/thank-you" >
        <input type="hidden" name="form-name" value={name} />

        <Flex width={'100%'} sx={{flexDirection: ['column', 'row'], marginTop: [8, 0]}}>
          { minimal ? 
            <div sx={{ flex: '1', marginRight: ['0px','20px'] }}>
            <label hidden htmlFor="full_name">Name</label>
            <input type="text" name="full_name" id="full_name" placeholder="Name"/>
            </div>
            :
            <div sx={{ flex: '1', marginRight: ['0px','20px'] }}>
              <label hidden htmlFor="first name">Name</label>
              <input type="text" name="first_name" id="first_name" placeholder="First Name"/>
            </div>
          }
          { minimal ? 
            <div sx={{flex: '1'}}>
              <label hidden htmlFor="email">Your e-mail address<span>*</span></label>
              <input type="email" name="email" id="email" placeholder="Email" />
            </div>
            :
            <div sx={{flex: '1'}}>
              <label hidden htmlFor="last name">Last name</label>
              <input type="text" name="last_name" id="last_name" placeholder="Last Name"/>
            </div>
          }
        </Flex>

        { !minimal && 
            <div>
              <label hidden htmlFor="email">Your e-mail address<span>*</span></label>
              <input type="email" name="email" id="email" placeholder="Email" />
            </div>  
        }
        { !minimal &&
            <div>
              <label hidden htmlFor="phone">Your phone number</label>
              <input type="tel" name="phone" id="phone" placeholder="Phone" />
            </div>
        }
      
        <div>
          <label hidden htmlFor="additional_info">Message</label>
          <textarea rows="7" name="additional_info" id="additional_info" placeholder="Enter your message here" />
        </div>

        <Button as="button" text="Submit" type="submit" />
      </Form>

    )
  }

}


ContactForm.propTypes = {
  // name: PropTypes.string.isRequired,
  minimal: PropTypes.bool,
 
}


export default ContactForm;