/** @jsx jsx */
import { Styled, Flex, jsx } from 'theme-ui'
import BgImage from '../components/BgImage'
import HeroBorder from '../components/HeroBorder'

const BasicHero = ({fluid, title, heroTitle, adjacentSectionColor, props}) => (
    <header
      {...props}
    >
      <BgImage
        fluid={fluid}
        title={title}
        height={'550px'}
        mobileHeight={'250px'}
        overlayColor={'rgba(0,0,0,0.6)'}
      >
        <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: ['250px', '550px', '550px'], textAlign: 'center'}}>
            <Styled.h1 sx={{
              fontSize: ['30px', '40px', '60px'],
              marginTop: ['70px', null ],
              color: 'white',
            }}>{heroTitle}</Styled.h1>
        </Flex>
      </BgImage>
      
      <HeroBorder sectionColor={adjacentSectionColor} />
    </header>
  
)

export default BasicHero

//TODO: Add sx property to allow for correct colors