/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
import styled from '@emotion/styled'
import Img from 'gatsby-image'


const CardWrapper = styled.article`
  flex: 1 1 100%;
  color: ${props => props.color || 'black' };
  background: ${props => props.bgColor || 'none' };
  padding: 15px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: ${props => props.border ? '2' : '0'}px solid black;
`

const ImgWrapper = styled.div`
  width: ${props => props.small ? 'auto' : '100%'};
  height: ${props => props.small ? '90px' : 'auto'};
  margin: 0 auto;
  overflow: hidden;
  border-radius: ${props => props.roundImage ? '50%' : '0'};
  
  img {
    width: 100%;
    height: 100%;
  }
`

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`

const CardText = styled.p`

`

const CardTitle = styled.h1`
  text-transform: capitalize;
  margin: 20px 0;
  font-size: 24px;
  color: ${props => props.titleColor || 'black'}
`

const VariableImage = (props) => {

  if(props.fluidImg) {
    return <Img fluid={props.fluidImg} />;
  }
  
  return <Image src={props.imgSrc} />;

}


const Card = ({
  title, 
  text, 
  imgSrc, 
  Icon,
  roundImage, 
  small, 
  color, 
  bgColor,
  border, 
  titleColor, 
  fluidImg,
  children}) => (
  <CardWrapper color={color} border={border} bgColor={bgColor}>
    <ImgWrapper roundImage={roundImage} small={small}>
      { Icon !== undefined 
        ? <Icon style={{width: '100%'}} />
        : <VariableImage fluidImg={fluidImg} imgSrc={imgSrc} />
      }
      
    </ImgWrapper>
    <CardContent>
      <CardTitle titleColor={titleColor}>{title}</CardTitle>
      { text !== undefined
        ? <CardText>{text}</CardText> :
        ''
      }  
      {children}
    </CardContent>
  </CardWrapper>
);

export default Card;