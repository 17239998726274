/** @jsx jsx */

import { Styled, jsx, Flex, Box, Grid } from "theme-ui"
import { graphql } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import Card from "../components/Card"
import Button from "../components/button"

import ContactForm from "../components/ContactForm"

import SEO from "../components/seo"

import BasicHero from "../components/BasicHero"
import BgImage from "../components/BgImage"

import QuestionIcon from "../images/icons/question-icon.svg"
import PackageIcon from "../images/icons/package-icon.svg"
import WarrantyIcon from "../images/icons/warranty-icon.svg"
import PrivacyIcon from "../images/icons/privacy-icon.svg"

import FacebookIcon from "../images/icons/facebook-black.svg"
import LinkedinIcon from "../images/icons/linkedin-black.svg"

import NextduraLogo from "../images/icons/nextdura-logo.svg"

import { Container } from "../components/item"

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "welding-closeup-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    abstract: file(relativePath: { eq: "white-abstract.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    welding: file(relativePath: { eq: "man-welding.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    abstractTwo: file(relativePath: { eq: "abstract-background-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const StyledNextduraLogo = styled(NextduraLogo)`
  width: 100%;
  height: auto;
`

const ContactLink = styled.a`
  text-decoration: none;
  color: inherit;
  border-bottom: 1px dotted #000;
`

const ContactPage = ({ data }) => (
  <Layout>
    <SEO
      title="Contact Nexprotek"
      // description=""
    />

    <BasicHero
      fluid={data.hero.childImageSharp.fluid}
      title={"Welding Closeup Herogat"}
      heroTitle={"Contact"}
      adjacentSectionColor={"darkGray"}
    />
    {/*     
    <Box as="section" color={'white'} sx={{
      position: 'relative',
      backgroundColor: 'darkGray',
    }}>
      <Container py={13}>
        <Grid columns={[2, 4]}>
          <Card
            textColor='white'
            titleColor='white'
            Icon={QuestionIcon}
            small
            title="shipping & returns" 
          >
            <Button text="Learn More" variant={'orangeOutline'} />
          </Card>
          <Card
            textColor='white'
            titleColor='white'
            Icon={PackageIcon}
            small
            title="product FAQs" 
          >
            <Button text="Learn More" variant={'orangeOutline'} />
          </Card>
          <Card
            textColor='white'
            titleColor='white'
            Icon={WarrantyIcon}
            small
            title="warranty" 
          >
            <Button text="Learn More" variant={'orangeOutline'} />
          </Card>
          <Card
            textColor='white'
            titleColor='white'
            Icon={PrivacyIcon}
            small
            title="privacy inquiry" 
          >
            <Button text="Learn More" variant={'orangeOutline'} />
          </Card>
        </Grid>
      </Container>
    </Box> */}

    <BgImage
      fluid={data.abstract.childImageSharp.fluid}
      title="abstract design"
      height="auto"
    >
      <Container py={13}>
        <Flex sx={{ flexDirection: ["column", "column", "row"] }}>
          <Flex sx={{ flex: "1 1 20%", flexDirection: "column" }}>
            <Styled.h3
              sx={{ textTransform: "uppercase", margin: 0, marginBottom: 2 }}
            >
              Contact Us
            </Styled.h3>
            <Styled.p>
              <ContactLink href="tel:18442284227">1-844-228-4227</ContactLink>
              <br />
              <ContactLink href="mailto:contact@nexprotek.com">
                contact@nexprotek.com
              </ContactLink>{" "}
              <br />
              15207 N 75th St Suite <br />
              #101 <br />
              Scottsdale, AZ 85260
            </Styled.p>

            {/* <Styled.h3 sx={{textTransform: 'uppercase', maxWidth: '300px', marginBottom: 2}}>Need Someone Specific?</Styled.h3>
            <Styled.h4 sx={{marginBottom: 1}}>Help & Support</Styled.h4>
            <Styled.p sx={{margin: 0}}>email@email.com</Styled.p>
            <Styled.h4 sx={{marginBottom: 1}}>Sales</Styled.h4>
            <Styled.p sx={{margin: 0}}>email@email.com</Styled.p>
            <Styled.h4 sx={{marginBottom: 1}}>Warranty</Styled.h4>
            <Styled.p sx={{marginTop: 0}}>email@email.com</Styled.p>
            <Styled.h3 sx={{textTransform: 'uppercase',  marginBottom: 2}}>Connect with us</Styled.h3>

            <Flex>
              <FacebookIcon sx={{marginRight: 4}}/>
              <LinkedinIcon />
            </Flex> */}
          </Flex>
          <Box
            sx={{
              flex: "1 2 610px",
              width: "100%",
              marginTop: ["60px", "60px", 0],
              alignSelf: ["center", "flex-start"],
            }}
          >
            <ContactForm name="Contact Page" />
          </Box>
        </Flex>
      </Container>
    </BgImage>

    {/* <Box as="section" bg='yellow'>  
      <Container py={[8,13]} sx={{textAlign: 'center'}}>
        <Flex sx={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Styled.h3 sx={{textTransform: 'uppercase'}}>Need wholesale options?</Styled.h3>
        <Box sx={{maxWidth: '450px'}}>
          <StyledNextduraLogo />
        </Box>
        </Flex>
      </Container>
    </Box> */}
  </Layout>
)

export default ContactPage
